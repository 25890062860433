// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyCBLUt68EkjDfTTF2GZ2zDc-X-pkEKwA5k",
  authDomain: "kf-demo-project-b0508.firebaseapp.com",
  projectId: "kf-demo-project-b0508",
  storageBucket: "kf-demo-project-b0508.appspot.com",
  messagingSenderId: "292117187785",
  appId: "1:292117187785:web:ecc0a1d4d4d58c4bd35118",
  measurementId: "G-ZMFCH487HW"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);


export const createCallableFunction = (functionName) => {
  return httpsCallable(functions, functionName);
};