import React from "react";



const BtnParentFrame = ({ text = "My Account", onClick, className, disabled = false, type = "button" }) => {
    return (
        <button 
            onClick={onClick}
            disabled={disabled}
            className={`bg-darkOrangeColor text-white
                        text-center py-2 px-4 
                        rounded-lg font-medium
                        hover:bg-white hover:text-darkOrangeColor hover:border hover:border-darkOrangeColor
                        transition duration-300 ease-in-out
                        transform hover:scale-105
                        ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
                        ${className}`}
        >
            {text}
        </button>
    );
};

export default BtnParentFrame;

