const romajiToHiraganaMap = {
    a: 'あ', i: 'い', u: 'う', e: 'え', o: 'お',
    ka: 'か', ki: 'き', ku: 'く', ke: 'け', ko: 'こ',
    sa: 'さ', shi: 'し', su: 'す', se: 'せ', so: 'そ',
    ta: 'た', chi: 'ち', tsu: 'つ', te: 'て', to: 'と',
    na: 'な', ni: 'に', nu: 'ぬ', ne: 'ね', no: 'の',
    ha: 'は', hi: 'ひ', fu: 'ふ', he: 'へ', ho: 'ほ',
    ma: 'ま', mi: 'み', mu: 'む', me: 'め', mo: 'も',
    ya: 'や', yu: 'ゆ', yo: 'よ',
    ra: 'ら', ri: 'り', ru: 'る', re: 'れ', ro: 'ろ',
    wa: 'わ', wo: 'を', nn: 'ん',
    ga: 'が', gi: 'ぎ', gu: 'ぐ', ge: 'げ', go: 'ご',
    za: 'ざ', ji: 'じ', zu: 'ず', ze: 'ぜ', zo: 'ぞ',
    da: 'だ', di: 'ぢ', du: 'づ', de: 'で', do: 'ど',
    ba: 'ば', bi: 'び', bu: 'ぶ', be: 'べ', bo: 'ぼ',
    pa: 'ぱ', pi: 'ぴ', pu: 'ぷ', pe: 'ぺ', po: 'ぽ',
    kya: 'きゃ', kyu: 'きゅ', kyo: 'きょ',
    sha: 'しゃ', shu: 'しゅ', sho: 'しょ',
    cha: 'ちゃ', chu: 'ちゅ', cho: 'ちょ',
    nya: 'にゃ', nyu: 'にゅ', nyo: 'にょ',
    hya: 'ひゃ', hyu: 'ひゅ', hyo: 'ひょ',
    mya: 'みゃ', myu: 'みゅ', myo: 'みょ',
    rya: 'りゃ', ryu: 'りゅ', ryo: 'りょ',
    gya: 'ぎゃ', gyu: 'ぎゅ', gyo: 'ぎょ',
    ja: 'じゃ', ju: 'じゅ', jo: 'じょ',
    bya: 'びゃ', byu: 'びゅ', byo: 'びょ',
    pya: 'ぴゃ', pyu: 'ぴゅ', pyo: 'ぴょ'
};

const romajiToKatakanaMap = {
    a: 'ア', i: 'イ', u: 'ウ', e: 'エ', o: 'オ',
    ka: 'カ', ki: 'キ', ku: 'ク', ke: 'ケ', ko: 'コ',
    sa: 'サ', shi: 'シ', su: 'ス', se: 'セ', so: 'ソ',
    ta: 'タ', chi: 'チ', tsu: 'ツ', te: 'テ', to: 'ト',
    na: 'ナ', ni: 'ニ', nu: 'ヌ', ne: 'ネ', no: 'ノ',
    ha: 'ハ', hi: 'ヒ', fu: 'フ', he: 'ヘ', ho: 'ホ',
    ma: 'マ', mi: 'ミ', mu: 'ム', me: 'メ', mo: 'モ',
    ya: 'ヤ', yu: 'ユ', yo: 'ヨ',
    ra: 'ラ', ri: 'リ', ru: 'ル', re: 'レ', ro: 'ロ',
    wa: 'ワ', wo: 'ヲ', nn: 'ン',
    ga: 'ガ', gi: 'ギ', gu: 'グ', ge: 'ゲ', go: 'ゴ',
    za: 'ザ', ji: 'ジ', zu: 'ズ', ze: 'ゼ', zo: 'ゾ',
    da: 'ダ', di: 'ヂ', du: 'ヅ', de: 'デ', do: 'ド',
    ba: 'バ', bi: 'ビ', bu: 'ブ', be: 'ベ', bo: 'ボ',
    pa: 'パ', pi: 'ピ', pu: 'プ', pe: 'ペ', po: 'ポ',
    kya: 'キャ', kyu: 'キュ', kyo: 'キョ',
    sha: 'シャ', shu: 'シュ', sho: 'ショ',
    cha: 'チャ', chu: 'チュ', cho: 'チョ',
    nya: 'ニャ', nyu: 'ニュ', nyo: 'ニョ',
    hya: 'ヒャ', hyu: 'ヒュ', hyo: 'ヒョ',
    mya: 'ミャ', myu: 'ミュ', myo: 'ミョ',
    rya: 'リャ', ryu: 'リュ', ryo: 'リョ',
    gya: 'ギャ', gyu: 'ギュ', gyo: 'ギョ',
    ja: 'ジャ', ju: 'ジュ', jo: 'ジョ',
    bya: 'ビャ', byu: 'ビュ', byo: 'ビョ',
    pya: 'ピャ', pyu: 'ピュ', pyo: 'ピョ'
};

export const convertRomajiToHiragana = (input, toKatakana = false) => {
  let output = '';
  let buffer = '';

  const isRomaji = (char) => /[a-zA-Z]/.test(char);
  const conversionMap = toKatakana ? romajiToKatakanaMap : romajiToHiraganaMap;
  const smallTsu = toKatakana ? 'ッ' : 'っ';

  for (let i = 0; i < input.length; i++) {
    const char = input[i].toLowerCase();
    if (isRomaji(char)) {
      // Check for double consonants (small tsu)
      if (buffer && char !== 'n' && char === buffer[buffer.length - 1]) {
        output += smallTsu;
        buffer = char;
      } else {
        buffer += char;
      }
    } else {
      output += convertBuffer(buffer, conversionMap) + char;
      buffer = '';
    }

    if (buffer === 'nn') {
      output += toKatakana ? 'ン' : 'ん';
      buffer = '';
    } else {
      let matched = false;
      for (let j = 3; j > 0; j--) {
        if (buffer.length >= j) {
          const substring = buffer.slice(0, j);
          if (conversionMap[substring]) {
            output += conversionMap[substring];
            buffer = buffer.slice(j);
            matched = true;
            break;
          }
        }
      }
      if (!matched && buffer.length > 2) {
        output += buffer.charAt(0);
        buffer = buffer.slice(1);
      }
    }
  }

  return output + convertBuffer(buffer, conversionMap);
};

const convertBuffer = (buffer, conversionMap) => {
  let result = '';
  while (buffer.length > 0) {
    let matched = false;
    for (let j = Math.min(buffer.length, 3); j > 0; j--) {
      const substring = buffer.slice(0, j);
      if (conversionMap[substring]) {
        result += conversionMap[substring];
        buffer = buffer.slice(j);
        matched = true;
        break;
      }
    }
    if (!matched) {
      result += buffer.charAt(0);
      buffer = buffer.slice(1);
    }
  }
  return result;
};