const REFRESH_INTERVAL = 5 * 1000; // 5 seconds in milliseconds

export function canRefresh() {
  const lastRefresh = localStorage.getItem('lastRefreshTimestamp');
  const now = Date.now();

  console.log('canRefresh - lastRefresh:', lastRefresh);
  console.log('canRefresh - now:', now);

  if (!lastRefresh || now - parseInt(lastRefresh) > REFRESH_INTERVAL) {
    console.log('canRefresh - Refresh allowed');
    return true;
  }

  console.log('canRefresh - Refresh not allowed');
  return false;
}

export function setLastRefresh() {
  const now = Date.now();
  localStorage.setItem('lastRefreshTimestamp', now.toString());
  console.log('setLastRefresh - New lastRefreshTimestamp set:', now);
}