import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import { getFirestore, collection, getDoc, doc } from 'firebase/firestore';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchAllKanjiData } from '../components/utils/fetchKanjiData';
import { createCallableFunction } from '../config/firebase';

import DashboardSummary from '../components/dashboardSummary';
import LevelProgressSection from '../components/levelProgressSection';
import KanjiStatusCircles from '../components/kanjiQueStatus';
import LevelStatsChart from '../components/levelStatsChart';

//images
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { BsFillGearFill } from "react-icons/bs";
import { CgClose } from "react-icons/cg";

//footer
import Footer from '../components/footer';

//utils
import { canRefresh, setLastRefresh } from '../components/utils/refreshThrottle';


function KanjiHomePage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const queryClient = useQueryClient();
    const [newLessons, setNewLessons] = useState(0);
    const [currentKanji, setCurrentKanji] = useState([]);
    const [homePageLoading, setHomePageLoading] = useState(true);
    const previousPath = location.state?.previousPath;
    const shouldRefetch = useRef(
        previousPath === '/reviews' || 
        previousPath === '/reviews/' || 
        previousPath === '/quiz/' || 
        previousPath === '/level-up/' || 
        previousPath === '/level-up'
    );
    const [dataFetched, setDataFetched] = useState(false);
    const [upcomingReviews, setUpcomingReviews] = useState({});
    const [isGuideOpen, setIsGuideOpen] = useState(false);
    const [isSpinning, setIsSpinning] = useState(false);
    const [showAlgoTraining, setShowAlgoTraining] = useState(true);

    const fetchUserProgress = async () => {
        if (!user) return [];
        const db = getFirestore();
        const userProgressRef = collection(db, 'userProgress');
        const docRef = doc(userProgressRef, user.uid);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists() && docSnapshot.data().currentKanji) {
            return docSnapshot.data().currentKanji;
        }
        return [];
    };

    const fetchAllData = async () => {
        if (!user) return { reviews: [], stats: { kanjiDetails: [], queStatusCounts: {} }, progress: [], upcomingReviews: {} };

        const loadKanjiToReview = createCallableFunction('loadKanjiToReview');
        const loadCurrentStats = createCallableFunction('loadCurrentStats');
        const getUpcomingReviews = createCallableFunction('getUpcomingReviews');

        const [reviewsData, statsData, progress, upcomingReviewsData] = await Promise.all([
            loadKanjiToReview(),
            loadCurrentStats(),
            fetchUserProgress(),
            getUpcomingReviews()
        ]);

        setUpcomingReviews(upcomingReviewsData.data);

        console.log('Fetched reviews:', reviewsData.data.preparedQuizList);
        console.log('Fetched stats:', statsData.data);
        console.log('Fetched progress:', progress);
        console.log('Fetched upcoming reviews:', upcomingReviewsData.data);


        return {
            reviews: reviewsData.data.preparedQuizList,
            totalKanjiAtLevel: statsData.data.totalKanjiAtLevel,
            ascensionCount: statsData.data.ascensionCount,
            currentLevel: statsData.data.currentLevel,
            isEligibleForLevelUp: statsData.data.isEligibleForLevelUp,
            stats: {
                kanjiDetails: statsData.data.kanjiDetails,
                queStatusCounts: statsData.data.queStatusCounts
            },
            progress,
            upcomingReviews: upcomingReviewsData.data
        };
    };

    const { data: allData, isLoading, isError, refetch } = useQuery({
        queryKey: ['allData', user?.uid],
        queryFn: fetchAllData,
        enabled: false,
        refetchOnWindowFocus: false,
        staleTime: 5 * 60 * 1000, // 5 minutes
    });


    const handleDataFetch = useCallback(async () => {
        if (!user) {
            navigate('/login');
            return;
        }

        const cachedData = queryClient.getQueryData(['allData', user.uid]);

        if (!cachedData && !canRefresh()) {
            console.log('Throttle limit reached, not fetching data.');
            setHomePageLoading(false);
            return;
        }

        console.log('shouldRefetch', shouldRefetch);

        if (shouldRefetch.current) {
            console.log('Invalidating cache and refetching data');
            queryClient.invalidateQueries(['allData', user.uid]);
            const startTime = Date.now();
            const { data: fetchedData } = await refetch();
            setNewLessons(fetchedData.progress.length);
            setCurrentKanji(fetchedData.progress);
            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(2000 - elapsedTime, 0);
            setTimeout(() => {
                setHomePageLoading(false);
                shouldRefetch.current = false;
            }, remainingTime);
        } else if (cachedData) {
            console.log('Using cached data');
            setNewLessons(cachedData.progress.length);
            setCurrentKanji(cachedData.progress);
            setHomePageLoading(false);
        } else {
            console.log('Fetching all data');
            const startTime = Date.now();
            const { data: fetchedData } = await refetch();
            setNewLessons(fetchedData.progress.length);
            setCurrentKanji(fetchedData.progress);
            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(3000 - elapsedTime, 0);
            setTimeout(() => setHomePageLoading(false), remainingTime);
        }

        setLastRefresh();
        setDataFetched(true);
    }, [user, navigate, queryClient, refetch]);

    useEffect(() => {
        document.title = "OniKanji - App Home";
        if (!userLoading && !dataFetched) {
            handleDataFetch();
        }

        const spinInterval = setInterval(() => {
            setIsSpinning(true);
            setTimeout(() => setIsSpinning(false), 3000); // Spin for 3 seconds
        }, 5000); // Start spinning every 10 seconds

        return () => clearInterval(spinInterval);
    }, [user, userLoading, handleDataFetch, dataFetched]);

    const handleNewLessonClick = async () => {
        if (newLessons === 0) return; // Prevent click if newLessons is 0

        const newLessonLimit = 4; // TODO: Make this dynamic later
        const kanjiToLearn = currentKanji.slice(0, Math.min(newLessonLimit, currentKanji.length));

        let allKanjiData = queryClient.getQueryData(['kanjiData', kanjiToLearn]);
        if (!allKanjiData) {
            allKanjiData = await fetchAllKanjiData(kanjiToLearn);
        }

        navigate('/new-lesson/', { state: { kanjiData: allKanjiData } });
    };

    const handleReviewsClick = () => {
        if (allData?.reviews?.length > 0) {
            navigate('/reviews', { state: { reviews: allData.reviews } });
        }
    };

    const [isLevelingUp, setIsLevelingUp] = useState(false);
    const handleLevelUpClick = async () => {
        if (subscriptionStatus !== "active" && allData?.currentLevel >= 3) {
            // Redirect to pricing page if subscription is not active and level is 3 or higher
            navigate('/pricing');
        } else {
            setIsLevelingUp(true);
            try {
                const updateUserLevel = createCallableFunction('updateUserLevel');
                const result = await updateUserLevel();
                if (result.data.success) {
                    navigate('/level-up/', { state: { levelStats: result.data.levelStats } });
                } else {
                    console.error('Failed to update user level:', result.data.message);
                    navigate('/error');
                }
            } catch (error) {
                console.error('Error updating user level:', error);
                navigate('/error');
            } finally {
                setIsLevelingUp(false);
            }
        }
    };

    const [activeStats, setActiveStats] = useState('accuracy');
    const [chartData, setChartData] = useState([]);

    const getDataKey = () => {
        switch (activeStats) {
            case 'accuracy': return 'accuracy';
            case 'speed': return 'speed';
            case 'reviewTime': return 'reviewTime';
            case 'currentStreak': return 'currentStreak';
            default: return '';
        }
    };

    const handleStatsSectionClick = (statType) => {
        setActiveStats(statType);
        let data;
        switch (statType) {
            case 'accuracy':
                data = allData?.stats?.kanjiDetails?.map(kanjiItem => ({ name: kanjiItem.kanji, accuracy: kanjiItem.totalAccuracy }));
                break;
            case 'speed':
                data = allData?.stats?.kanjiDetails?.map(kanjiItem => ({ name: kanjiItem.kanji, speed: parseFloat(kanjiItem.averageSpeed).toFixed(3) }));
                break;
            case 'reviewTime':
                data = allData?.stats?.kanjiDetails?.map(kanjiItem => ({ name: kanjiItem.kanji, reviewTime: kanjiItem.totalTimeToAnswer }));
                break;
            case 'currentStreak':
                data = allData?.stats?.kanjiDetails?.map(kanjiItem => ({ name: kanjiItem.kanji, currentStreak: kanjiItem.currentStreak }));
                break;
            default:
                data = [];
        }
        setChartData(data);
    };

    useEffect(() => {
        if (allData?.stats?.kanjiDetails) {
            handleStatsSectionClick('accuracy');
        }
    }, [allData]);

    const handleRefreshClick = async () => {
        const updateUserReviewBucketsOnDemand = createCallableFunction('updateUserReviewBucketsOnDemand');
        try {
            const result = await updateUserReviewBucketsOnDemand();
            if (result.data && result.data.success) {
                console.log('Review buckets updated successfully:', result.data.updatedBuckets);
                setUpcomingReviews(result.data.updatedBuckets);
                // Update the cached data
                queryClient.setQueryData(['allData', user.uid], (oldData) => ({
                    ...oldData,
                    upcomingReviews: result.data.updatedBuckets
                }));
            } else {
                console.error('Failed to update review buckets:', result.data ? result.data.message : 'Unknown error');
            }
        } catch (error) {
            console.error('Error updating review buckets:', error);
        }
    };

    function SkeletonLoader() {
        return (
            <div className="animate-pulse space-y-4 sm:space-y-6">
                {/* Guide Section */}
                <div className="bg-gray-300 p-4 rounded-lg shadow-lg h-24 sm:h-32"></div>
    
                {/* DashboardSummary */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {[1, 2, 3].map((i) => (
                        <div key={i} className="bg-gray-300 p-4 rounded-lg shadow-lg h-32 sm:h-40 lg:h-48"></div>
                    ))}
                </div>
    
                {/* LevelProgressSection */}
                <div className="bg-gray-300 p-4 rounded-lg shadow-lg h-48 sm:h-56 lg:h-64"></div>
    
                {/* KanjiStatusCircles */}
                <div className="bg-gray-300 p-4 rounded-lg shadow-lg h-40 sm:h-48"></div>
    
                {/* LevelStatsChart */}
                <div className="bg-gray-300 p-4 rounded-lg shadow-lg h-48 sm:h-56 lg:h-64"></div>
    
                {/* Critical Condition Kanji */}
                <div className="bg-gray-300 p-4 rounded-lg shadow-lg h-40 sm:h-48"></div>
            </div>
        );
    }

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />

            <div className="bg-mainBackgroundColor min-h-screen flex flex-col items-center p-4">
                <div className="max-w-screen-xl w-full bg-mainBackgroundColor p-6 mt-2">
                    {homePageLoading || isLoading ? (
                        <SkeletonLoader />
                    ) : (
                        <>
                            {/* Guide Section */}
                            <div className="max-w-screen-xl w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                                <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsGuideOpen(!isGuideOpen)}>
                                    <div>
                                        <div className="text-xl font-semibold">OniKanji v0.1.0 Alpha</div>
                                        <div className="text-sm mt-2">Click to {isGuideOpen ? 'hide' : 'show'} show the changelog and quick start guide</div>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="hidden sm:inline mr-2">{isGuideOpen ? 'Hide guide' : 'Read more'}</span>
                                        {isGuideOpen ? <FaChevronUp className="w-4 h-4" /> : <FaChevronDown className="w-4 h-4" />}
                                    </div>
                                </div>

                                {isGuideOpen && (
                                    <div className="mt-6 space-y-4">

                                        <div className="bg-gray-100 p-4 rounded-lg">
                                            <h3 className="text-xl text-darkBlueColor font-bold mb-4">Changelog/Updates</h3>
                                            <ul className="list-disc list-inside space-y-1">
                                                <li>Payments Setup</li>
                                                <li>Bug Fixes</li>
                                                <li>Sound Effects Added</li>
                                            </ul>
                                        </div>

                                        <p className="text-lg font-semibold mt-4">Welcome to OniKanji. I hope you're excited to start your kanji journey. It's going to be tough.</p>
                                        <p className="text-lg font-semibold mt-4">Follow the below tips and tricks to get started. 頑張ってね！</p>

                                        <div className="bg-gray-100 p-4 rounded-lg">
                                            <h3 className="text-xl text-darkBlueColor font-bold mb-2">Quick Start Guide</h3>
                                            <div className="mb-4" />
                                            <ol className="list-decimal list-inside space-y-2">
                                                <li>Read the getting started guide on the knowledge base <a href="https://knowledge.onikanji.com/" className="text-blue-600 hover:underline">here</a>.</li>
                                                <li>Join the active discord community here.</li>
                                                <li>If you've reached level 3, you can upgrade your subscritpion to access the full feature set <a href="/pricing" className="text-blue-600 hover:underline">here</a>.</li>
                                            </ol>
                                        </div>
                                    </div>
                                )}
                            </div>
                            
                            {/* Algo in Training Section */}
                            {showAlgoTraining && allData?.currentLevel <= 3 && ( // Conditional check added here
                                <div className="max-w-screen-xl w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10 relative">
                                    <div className="flex justify-between items-center cursor-pointer">
                                        <div>
                                            <div className="text-xl font-semibold">Your Custom Learning Algorithm is In Training</div>
                                            <div className="text-sm mt-2">Complete 3 levels to begin supercharging your journey with machine learning</div>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="hidden sm:inline mr-2">Learn More</span>
                                            <BsFillGearFill 
                                                className={`w-6 h-6 text-darkBlueColor ${isSpinning ? 'animate-spin' : ''}`} 
                                                style={{ animationDuration: '3s' }}
                                            />
                                        </div>
                                    </div>
                                    <CgClose 
                                        className="absolute top-2 right-2 w-3 h-3 text-gray-500 hover:text-gray-700 cursor-pointer" // Reduced size
                                        onClick={() => setShowAlgoTraining(false)}
                                    />
                                </div>
                            )}


                            <DashboardSummary
                                newLessons={newLessons}
                                reviews={allData?.reviews?.length || 0}
                                upcomingReviews={allData?.upcomingReviews || {}}
                                onNewLessonClick={handleNewLessonClick}
                                onReviewsClick={handleReviewsClick}
                                onRefreshClick={handleRefreshClick}
                            />

                            <LevelProgressSection
                                currentLevel={allData?.currentLevel}
                                isEligibleForLevelUp={allData?.isEligibleForLevelUp}
                                isLevelingUp={isLevelingUp}
                                onLevelUpClick={handleLevelUpClick}
                                kanjiDetails={allData?.stats?.kanjiDetails}
                                subscriptionStatus={subscriptionStatus}
                            />
                            <KanjiStatusCircles queStatusCounts={allData?.stats?.queStatusCounts || {}} />

                            <LevelStatsChart
                                currentLevel={allData?.currentLevel}
                                activeStats={activeStats}
                                chartData={chartData}
                                handleStatsSectionClick={handleStatsSectionClick}
                            />

                            {/* Critical Condition Kanji */}
                            <div className="max-w-screen-xl w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10 mb-16">
                                <h2 className="text-2xl sm:text-3xl font-bold text-black mb-4 sm:mb-6 sm:text-left">Critical Condition Kanji</h2>
                                <div className="text-lg mb-4">
                                    {allData?.stats?.kanjiDetails
                                        ? (allData.stats.kanjiDetails.filter(kanji => kanji.totalAccuracy > 0 && kanji.totalAccuracy < 0.5).length > 0
                                            ? "Review these kanji for some extra practice. They seem to be giving you some trouble."
                                            : "No Kanji in critical status. Keep up the hardwork!")
                                        : "Error when calculating critical status kanji"}
                                </div>
                                <div className='grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-2 mt-6'>
                                    {allData?.stats?.kanjiDetails
                                        ? allData.stats.kanjiDetails
                                            .filter(kanji => kanji.totalAccuracy > 0 && kanji.totalAccuracy < 0.5)
                                            .map((kanji) => (
                                                <Link to={`/kanji/${kanji.kanji}`} key={kanji.kanji} className='flex flex-col items-center p-2 bg-white border rounded-lg shadow hover:bg-darkBlueColor transition duration-300'>
                                                    <div className='text-3xl font-bold mb-1'>{kanji.kanji}</div>
                                                    <div className='text-gray-600 text-sm'>{kanji.primaryMeaning}</div>
                                                </Link>
                                            ))
                                        : <div>Error when calculating critical status kanji</div>}
                                </div>
                            </div>


                        </>
                    )}
                </div>
            </div>
            <Footer />

        </div>
    );
}

export default KanjiHomePage;
