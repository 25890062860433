import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import { BiVolumeFull } from "react-icons/bi";


function KanjiNewLesson() {

    const location = useLocation();
    const newLessonKanjiDataFromHomePage = location.state?.kanjiData;

    const [kanjiList, setKanjiList] = useState([]);
    const [kanjiData, setKanjiData] = useState({});
    const [selectedKanji, setSelectedKanji] = useState(null);
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Examples');
    const [loading, setLoading] = useState(true);
    const [trainingButtonActive, setTrainingButtonToActive] = useState(false);

    useEffect(() => {
        if (!userLoading && !user) {
            navigate('/login');
        } else if (newLessonKanjiDataFromHomePage) {
            const kanjiKeys = Object.keys(newLessonKanjiDataFromHomePage);
            setKanjiList(kanjiKeys);
            setSelectedKanji(kanjiKeys[0]);
            setKanjiData(newLessonKanjiDataFromHomePage);
            setLoading(false);
        }
    }, [user, userLoading, newLessonKanjiDataFromHomePage, navigate]);


    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleKanjiClick = (kanji) => {
        setSelectedKanji(kanji);
    };

    useEffect(() => {
        const navigateNext = () => {
            const currentIndex = kanjiList.indexOf(selectedKanji);
            if (activeTab === 'Examples') {
                setActiveTab('Common Expressions');
            } else if (activeTab === 'Common Expressions') {
                if (currentIndex < kanjiList.length - 1) {
                    setActiveTab('Examples');
                    setSelectedKanji(kanjiList[currentIndex + 1]);
                } else {
                    setTrainingButtonToActive(true);
                }
            }
        };

        const navigatePrevious = () => {
            const currentIndex = kanjiList.indexOf(selectedKanji);
            setTrainingButtonToActive(false);
            if (activeTab === 'Common Expressions') {
                setActiveTab('Examples');
            } else if (activeTab === 'Examples' && currentIndex > 0) {
                setActiveTab('Common Expressions');
                setSelectedKanji(kanjiList[currentIndex - 1]);
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                navigateNext();
            } else if (event.key === 'ArrowLeft') {
                navigatePrevious();
            }
        };

        const checkLastKanjiAndExamples = () => {
            const currentIndex = kanjiList.indexOf(selectedKanji);
            if (currentIndex === kanjiList.length - 1 && activeTab === 'Common Expressions') {
                setTrainingButtonToActive(true);
            } else {
                setTrainingButtonToActive(false);
            }
        };

        checkLastKanjiAndExamples();

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [kanjiList, selectedKanji, activeTab]);


    const handleQuizClick = () => {
        navigate(`/quiz/`, { state: { kanjiData: newLessonKanjiDataFromHomePage } });
    };

    const handleTextToSpeech = (text, language) => {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = language === 'japanese' ? 'ja-JP' : 'en-US';
        window.speechSynthesis.speak(utterance);
    };

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />

            <div className="bg-mainBackgroundColor min-h-screen flex flex-col items-center p-4">
                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">

                    <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                        <div className="text-6xl sm:text-6xl font-bold text-black text-center m-4">{selectedKanji}</div>
                        <div className="text-center px-2 sm:px-4 py-2 sm:py-3 text-xl sm:text-3xl font-semibold">
                            {kanjiData[selectedKanji]?.primaryMeaning || 'No meaning available'}
                        </div>

                        {kanjiData[selectedKanji]?.secondaryMeaning && (
                            <div className="text-center px-2 sm:px-4 py-2 sm:py-3 text-xl sm:text-3xl font-semibold">
                                {kanjiData[selectedKanji].secondaryMeaning.split(',').map(meaning => meaning.trim().charAt(0).toUpperCase() + meaning.trim().slice(1)).join(', ')}
                            </div>
                        )}

                        <div className="flex w-full justify-center mt-4 space-x-4">
                            <div className={`text-center p-2 sm:p-3 text-sm sm:text-base rounded flex-1 ${kanjiData[selectedKanji]?.onyomi?.readings?.length ? 'bg-lightBlueBackground text-lightBlueText' : 'bg-gray-200'}`}>
                                <div className="font-semibold mb-2">On'yomi Readings</div>
                                {kanjiData[selectedKanji]?.onyomi?.readings?.map((reading, index) => (
                                    <div key={index} className="text-3xl sm:text-3xl mt-1 font-medium">{reading}</div>
                                ))}
                            </div>
                            <div className={`text-center p-2 sm:p-3 text-sm sm:text-base rounded flex-1 ${kanjiData[selectedKanji]?.kunyomi?.readings?.length ? 'bg-orange-100 text-orange-800' : 'bg-gray-200'}`}>
                                <div className="font-semibold mb-2">Kun'yomi Readings</div>
                                {kanjiData[selectedKanji]?.kunyomi?.readings?.map((reading, index) => (
                                    <div key={index} className="text-3xl sm:text-3xl mt-1 font-medium">{reading}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                    <div className="grid grid-cols-2 gap-3 sm:gap-4 mb-6">
                        <button
                            className={`text-sm sm:text-base font-semibold p-2 sm:p-3 rounded ${activeTab === 'Examples' ? 'bg-darkOrangeColor text-white' : 'bg-gray-300 text-black'}`}
                            onClick={() => handleTabClick('Examples')}
                        >
                            Examples
                        </button>
                        <button
                            className={`text-sm sm:text-base font-semibold p-2 sm:p-3 rounded ${activeTab === 'Common Expressions' ? 'bg-darkOrangeColor text-white' : 'bg-gray-300 text-black'}`}
                            onClick={() => handleTabClick('Common Expressions')}
                        >
                            Common Expressions
                        </button>
                    </div>
                    {activeTab === 'Examples' && (
                        <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                            <div className="w-full p-4 rounded-lg">
                                <div className="text-xl sm:text-2xl font-semibold text-black">Vocabulary & Examples</div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
                                {/* On'yomi Card */}
                                {kanjiData[selectedKanji]?.onyomi?.vocabWords?.length > 0 && (
                                    <div className="space-y-6">
                                        {kanjiData[selectedKanji].onyomi.vocabWords.map((vocabWord, index) => (
                                            <div key={`onyomi-${index}`} className="bg-lightBlueBackground p-6 rounded-lg shadow-md space-y-4 w-full">
                                                <div className="space-y-2">
                                                    <p className="text-darkBlueColor text-sm">Vocabulary Word</p>
                                                    <div className="flex flex-col md:flex-row items-start justify-between">
                                                        <div className="text-3xl font-medium text-lightBlueText">{vocabWord.word}</div>
                                                        <div className="text-xl text-darkBlueColor ml-0 md:ml-4">{vocabWord.meaning}</div>
                                                    </div>
                                                    <p className="text-darkBlueColor text-xl">{vocabWord.reading}</p>
                                                </div>
                                                {vocabWord.sampleSentence && (
                                                    <div className="space-y-2 mt-4">
                                                        <p className="text-darkBlueColor text-sm">Sample Sentence</p>
                                                        <div className="flex items-center">
                                                            <div className="text-2xl font-medium text-lightBlueText">
                                                                {vocabWord.sampleSentence.split('(')[0].trim()}
                                                            </div>
                                                            <span 
                                                                className="text-darkBlueColor text-2xl ml-2 cursor-pointer"
                                                                onClick={() => handleTextToSpeech(vocabWord.sampleSentence.split('(')[0].trim(), 'japanese')}
                                                            >
                                                                <BiVolumeFull />
                                                            </span>
                                                        </div>
                                                        <div className="text-darkBlueColor text-xl">{vocabWord.sampleSentenceMeaning}</div>
                                                        {vocabWord.sampleSentence.includes('(') && (
                                                            <div className="text-darkBlueColor text-sm italic mt-2">
                                                                ({vocabWord.sampleSentence.split('(')[1].split(')')[0]})
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* Kun'yomi Card */}
                                {kanjiData[selectedKanji]?.kunyomi?.vocabWords?.length > 0 && (
                                    <div className="space-y-6">
                                        {kanjiData[selectedKanji].kunyomi.vocabWords.map((vocabWord, index) => (
                                            <div key={`kunyomi-${index}`} className="bg-orange-100 p-6 rounded-lg shadow-md space-y-4 w-full">
                                                <div className="space-y-2">
                                                    <p className="text-orange-600 text-sm">Vocabulary Word</p>
                                                    <div className="flex flex-col md:flex-row items-start justify-between">
                                                        <div className="text-3xl font-medium text-orange-900">{vocabWord.word}</div>
                                                        <p className="text-xl text-orange-700 ml-0 md:ml-4">{vocabWord.meaning}</p>
                                                    </div>
                                                    <p className="text-orange-700 text-xl">{vocabWord.reading}</p>
                                                </div>
                                                {vocabWord.sampleSentence && (
                                                    <div className="space-y-2 mt-4">
                                                        <p className="text-orange-600 text-sm">Sample Sentence</p>
                                                        <div className="flex items-center">
                                                            <div className="text-2xl font-medium text-orange-900">
                                                                {vocabWord.sampleSentence.split('(')[0].trim()}
                                                            </div>
                                                            <span 
                                                                className="text-orange-700 text-2xl ml-2 cursor-pointer"
                                                                onClick={() => handleTextToSpeech(vocabWord.sampleSentence.split('(')[0].trim(), 'japanese')}
                                                            >
                                                                <BiVolumeFull />
                                                            </span>
                                                        </div>
                                                        <div className="text-orange-700 text-xl">{vocabWord.sampleSentenceMeaning}</div>
                                                        {vocabWord.sampleSentence.includes('(') && (
                                                            <div className="text-orange-600 text-sm italic mt-2">
                                                                ({vocabWord.sampleSentence.split('(')[1].split(')')[0]})
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {activeTab === 'Common Expressions' && (
                        <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                            <div className="w-full p-4 rounded-lg">
                                <div className="text-xl sm:text-2xl font-semibold text-black">Common Expressions</div>
                            </div>
                            <div className="text-center text-lg">Placeholder for common expressions related to {selectedKanji}</div>
                        </div>
                    )}
                </div>

                <div className="max-w-screen-lg w-full mx-auto mt-6 sm:mt-10">
                    <h2 className="text-xl font-bold text-center p-4">Upcoming Kanji Battles</h2>
                    <div className="flex flex-wrap justify-center space-x-4">
                        {kanjiList.map((kanji, index) => (
                            <div
                                key={index}
                                className={`text-2xl p-4 m-2 rounded-lg shadow-blueBoxShadow flex items-center justify-center cursor-pointer ${selectedKanji === kanji ? 'bg-lightBlueBackground text-darkBlueColor' : 'bg-gray-200 text-black'}`}
                                onClick={() => handleKanjiClick(kanji)}
                            >
                                {kanji}
                            </div>
                        ))}
                        <div
                            className={`text-2xl p-4 m-2 rounded-lg shadow-md flex items-center justify-center cursor-pointer ${trainingButtonActive
                                ? "bg-green-500 hover:bg-green-600 animate-pulse"
                                : "bg-gray-300 text-gray-500 cursor-not-allowed"
                                }`}
                            onClick={trainingButtonActive ? handleQuizClick : undefined}
                        >
                            Training Grounds
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default KanjiNewLesson;