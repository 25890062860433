import React, { useState, useRef, useEffect } from "react";
import BtnParentFrame from './buttons';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase';

import logoImage from '../images/landingPageAssets/onikanji-logo-landing-page.png';

const Navbar = ({ user, currentPath, subscriptionStatus }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleNavigation = (path) => {
        setIsMenuOpen(false);
        navigate(path, { state: { previousPath: currentPath } });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const NavItem = ({ to, children }) => (
        <NavLink
            to={to}
            state={{ previousPath: currentPath }}
            className={({ isActive }) =>
                `px-4 py-2 rounded hover:underline focus:outline-none font-semibold ${
                    isActive ? 'text-darkOrangeColor' : ''
                }`
            }
        >
            {children}
        </NavLink>
    );

    return (
        <nav className="text-black p-2 relative" role="navigation">
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex-shrink-0">
                    <img 
                        src={logoImage} 
                        className="h-12 cursor-pointer" 
                        alt="OniKanji Logo" 
                        onClick={() => navigate('/')} 
                    />
                </div>

                {/* Desktop Menu */}
                <div className="hidden md:flex flex-grow justify-center">
                    <NavItem to="/home">Home</NavItem>
                    <NavItem to="/kanji">Kanji</NavItem>
                    <NavItem to="/my-stats">My Stats</NavItem>
                    <NavItem to="https://knowledge.onikanji.com/">Knowledge Base</NavItem>
                    {subscriptionStatus !== 'active' && <NavItem to="/pricing">Pricing</NavItem>}
                    {user && <NavItem to="/my-profile">My Profile</NavItem>}
                </div>

                {/* Desktop Login/Logout */}
                <div className="hidden md:block">
                    {
                     user ? (
                        <BtnParentFrame text="Log Out" onClick={handleSignOut} className="bg-darkOrangeColor" />
                    ) : (
                        <>
                            <button onClick={() => handleNavigation('/login')} className="px-4 py-2 rounded hover:underline focus:outline-none font-semibold">Login</button>
                            <BtnParentFrame text="Sign Up" onClick={() => handleNavigation('/register')} className="bg-darkOrangeColor" />
                        </>
                    )}
                </div>

                {/* Hamburger Menu */}
                <div className="md:hidden" ref={menuRef}>
                    <button 
                        onClick={() => setIsMenuOpen(!isMenuOpen)} 
                        className="p-4"
                        aria-label="Toggle menu"
                        aria-expanded={isMenuOpen}
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>

                    {isMenuOpen && (
                        <div className="absolute right-0 top-full mt-2 py-2 w-48 bg-white rounded-lg shadow-xl z-50">
                            <button onClick={() => handleNavigation('/home')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 font-semibold">Home</button>
                            <button onClick={() => handleNavigation('/kanji')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 font-semibold">Kanji</button>
                            <button onClick={() => handleNavigation('/my-stats')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 font-semibold">My Stats</button>
                            <a href="https://knowledge.onikanji.com/" className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 font-semibold">Help</a>
                            {subscriptionStatus !== 'active' && <button onClick={() => handleNavigation('/pricing')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 font-semibold">Pricing</button>}
                            {user && <button onClick={() => handleNavigation('/my-profile')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 font-semibold">My Profile</button>}
                            {user ? (
                                <button onClick={handleSignOut} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-darkOrangeColor font-semibold">Log Out</button>
                            ) : (
                                <>
                                    <button onClick={() => handleNavigation('/login')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-darkOrangeColor font-semibold">Login</button>
                                    <button onClick={() => handleNavigation('/register')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-darkOrangeColor font-semibold">Sign Up</button>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;