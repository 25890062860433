import React, { useEffect, useState } from 'react';
import { useUser } from '../components/utils/UserContext';
import Navbar from '../components/navBar';
import { useNavigate, Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../config/firebase'; 

function AllKanji() {
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const navigate = useNavigate();

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const levelsPerPage = 5;
    const [totalLevels, setTotalLevels] = useState(0);
    const totalPages = Math.ceil(totalLevels / levelsPerPage);

    useEffect(() => {
        if (!userLoading && !user) {
            navigate('/login');
        }
    }, [user, userLoading, navigate]);

    // Fetch total number of levels
    useEffect(() => {
        const fetchTotalLevels = async () => {
            try {
                // Fetch distinct levels from Firestore
                const levelsSnapshot = await getDocs(query(collection(db, "allKanji")));
                const levelsSet = new Set();
                levelsSnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data && data.level) {
                        levelsSet.add(parseInt(data.level));
                    }
                });
                const maxLevel = Math.max(...levelsSet);
                setTotalLevels(maxLevel);
            } catch (error) {
                console.error("Error fetching total levels:", error);
            }
        };
        fetchTotalLevels();
    }, []);

    // Calculate levels to show based on current page
    const startLevel = (currentPage - 1) * levelsPerPage + 1;
    const endLevel = Math.min(startLevel + levelsPerPage - 1, totalLevels);

    const kanjiLevelsToShow = [];
    for (let level = startLevel; level <= endLevel; level++) {
        kanjiLevelsToShow.push(level.toString());
    }

    const fetchAllKanjiDataByLevel = async ({ queryKey }) => {
        const [_key, { kanjiLevelsToShow }] = queryKey;
        console.log("Fetching Kanji data for levels:", kanjiLevelsToShow);
        const q = query(
            collection(db, "allKanji"), 
            where("level", "in", kanjiLevelsToShow),
            orderBy("lexicographicKanjiID")
        );
        const querySnapshot = await getDocs(q);
        console.log("Fetched Kanji data:", querySnapshot.docs.map(doc => doc.data()));
        return querySnapshot.docs.map(doc => doc.data());
    };

    const { data: kanjiList, isLoading: loadingKanji, error } = useQuery({
        queryKey: ['kanjiData', { kanjiLevelsToShow }],
        queryFn: fetchAllKanjiDataByLevel,
        onSuccess: (data) => console.log("Kanji data loaded successfully", data),
        onError: (error) => console.error("Error fetching Kanji data", error)
    });

    // Wait for user and kanji data to load
    if (userLoading || loadingKanji) {
        return null; // Return null to display nothing while loading
    }

    if (error) {
        navigate('/error', { state: { errorMessage: `Error fetching Kanji: ${error.message}`, location: 'All Kanji Page' } });
        return null;
    }

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />

            <div className='container mx-auto space-y-4 p-6'>
                {kanjiLevelsToShow.map(level => (
                    <React.Fragment key={level}>
                        <div className='flex flex-col items-center bg-lightBlueBackground text-lightBlueText p-4 rounded-lg mt-10'>
                            <div className='text-2xl font-bold'>Level {level} Kanji</div>
                            <div className='text-lg'>Joyo Kanji {(level - 1) * 20 + 1}-{level * 20}</div>
                        </div>

                        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-6'>
                            {kanjiList.filter(kanji => kanji.level === level.toString()).map((kanji) => (
                                <Link to={`/kanji/${kanji.kanji}`} key={kanji.kanji} className='flex flex-col items-center p-4 bg-white border rounded-lg shadow hover:bg-blue-100 transition duration-300'>
                                    <div className='text-4xl font-bold mb-2'>{kanji.kanji}</div>
                                    <div className='text-gray-600'>{kanji.primaryMeaning}</div>
                                </Link>
                            ))}
                        </div>
                    </React.Fragment>
                ))}

                {/* Pagination Buttons */}
                <div className='flex justify-between mt-6'>
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className={`px-4 py-2 rounded ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className={`px-4 py-2 rounded ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AllKanji;
