import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import RadialSeparators from './RadialSeperators';
import 'react-circular-progressbar/dist/styles.css';
import { LuPartyPopper } from "react-icons/lu";
import { ClipLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LevelProgressSection({ 
    currentLevel, 
    isEligibleForLevelUp, 
    onLevelUpClick, 
    kanjiDetails,
    isLevelingUp,
    subscriptionStatus
}) {
    const completedKanji = kanjiDetails?.filter(kanjiItem => kanjiItem.experience >= 60).length || 0;
    const totalKanji = kanjiDetails?.length || 0;
    const remainingKanji = totalKanji - completedKanji;
    const progressPercentage = (completedKanji / (totalKanji || 1)) * 100;
    const levelUpButtonText = subscriptionStatus !== "active" && currentLevel >= 3
    ? "Upgrade to Continue"
    : "Level Up!";

    return (
        <div className="max-w-screen-xl w-full bg-white p-6 mt-10 shadow-blueBoxShadow rounded-lg">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <h2 className="text-3xl font-bold text-black mb-4 sm:mb-0">Level {currentLevel} Progress</h2>
                <div className="flex flex-col sm:flex-row w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4">
                {subscriptionStatus !== 'active' && (
                    <Link to="/pricing" className="w-full sm:w-auto px-6 py-3 bg-gradient-to-r from-[#014156] to-[#DFA06A] text-white font-bold rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-105 text-center">
                        <span className="mr-2">🚀</span>
                        Upgrade For Maximum Kanji Learning Power
                        <span className="ml-2">🔓</span>
                    </Link>
                )}
                    <button
                        className={`w-full sm:w-auto px-5 py-3 rounded-lg shadow-md flex items-center justify-center space-x-2 transition-colors duration-300
                            ${isEligibleForLevelUp 
                                ? 'bg-white text-black border-2 border-darkBlueColor hover:bg-darkBlueColor hover:text-white' 
                                : 'bg-gray-500 text-black'}`}
                        disabled={!isEligibleForLevelUp || isLevelingUp}
                        style={{ opacity: isEligibleForLevelUp && !isLevelingUp ? 1 : 0.5 }}
                        onClick={onLevelUpClick}
                    >
                        {isLevelingUp ? (
                            <ClipLoader color="#000000" size={24} />
                        ) : (
                            <>
                                <span>{levelUpButtonText}</span>
                                {levelUpButtonText === "Level Up!" && (
                                    <LuPartyPopper size={24} className={isEligibleForLevelUp ? 'text-black group-hover:text-white' : 'text-black'} />
                                )}
                            </>
                        )}
                    </button>
                </div>
            </div>

            <div className="mt-6 mb-6">
                <div className="flex justify-between items-center mb-2">
                    <span>{completedKanji} Kanji Completed</span>
                    <span>{remainingKanji} Kanji Remaining</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                        className="bg-darkBlueColor h-2.5 rounded-full"
                        style={{ width: `${progressPercentage}%` }}
                    ></div>
                </div>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
                {kanjiDetails?.map((kanjiItem, index) => (
                    <a
                        key={index}
                        href={`/kanji/${kanjiItem.kanji}`}
                        className="p-2 flex flex-col items-center justify-center hover:scale-105"
                        title={`Experience: ${kanjiItem.experience}`}
                    >
                        <div style={{ width: '80px', height: '80px' }}>
                            <CircularProgressbarWithChildren
                                value={kanjiItem.experience}
                                strokeWidth={10}
                                styles={buildStyles({
                                    strokeLinecap: "butt",
                                    trailColor:"#BCCCBF",
                                    pathColor:  kanjiItem.experience >= 60 ? '#009BCE' : "#DFA06A",
                                })}
                            >
                                <RadialSeparators
                                    count={8}
                                    style={{
                                        background: "#fff",
                                        width: "2px",
                                        height: `${10}%`
                                    }}
                                />
                                <span className="text-3xl font-bold" style={{ color: kanjiItem.experience >= 60 ? '#009BCE' : 'inherit' }}>{kanjiItem.kanji}</span>
                            </CircularProgressbarWithChildren>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default LevelProgressSection;