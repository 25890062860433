import React, { useState, useEffect } from 'react';
import { useUser } from '../components/utils/UserContext';
import { getFirestore, collection, query, where, getDocs, addDoc, onSnapshot } from 'firebase/firestore';
import { useQuery } from '@tanstack/react-query';
import Footer from '../components/footer';
import Navbar from '../components/navBar';
import { ClipLoader } from 'react-spinners'; // Add this import

//pricing images
import pricingFishImage from '../images/landingPageAssets/onikanji-pricing-fish.png';
import pricingCameraImage from '../images/landingPageAssets/onikanji-pricing-camera.png';
import priciingCicadaImage from '../images/landingPageAssets/onikanji-pricing-cicada.png';


function OnikanjiPricing() {
    const db = getFirestore();
    const { user, loading: userLoading, subscriptionStatus, subscriptionName } = useUser();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            const productsRef = collection(db, 'subscriptionPlans');
            const q = query(productsRef, where('active', '==', true));
            const querySnapshot = await getDocs(q);
            
            const fetchedProducts = [];
            for (const doc of querySnapshot.docs) {
                console.log(doc.id, ' => ', doc.data());
                const productData = doc.data();
                const pricesRef = collection(doc.ref, 'prices');
                const priceSnap = await getDocs(pricesRef);
                const prices = [];
                priceSnap.docs.forEach((priceDoc) => {
                    console.log(priceDoc.id, ' => ', priceDoc.data());
                    prices.push({
                        id: priceDoc.id,
                        ...priceDoc.data()
                    });
                });
                fetchedProducts.push({ id: doc.id, ...productData, prices });
            }
            setProducts(fetchedProducts);
        };

        fetchProducts();
    }, [db]);

    const handleSubscribe = async (product) => {
        if (!user) {
            alert('Please log in to subscribe');
            return;
        }

        setLoading(true);

        const priceId = product.prices[0].id;

        try {
            const docRef = await addDoc(collection(db, 'UserCollection', user.uid, 'checkout_sessions'), {
                price: priceId,
                success_url: `${window.location.origin}/home`,
                cancel_url: `${window.location.origin}/pricing`,
            });

            onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data();
                if (error) {
                    alert(`An error occurred: ${error.message}`);
                    setLoading(false);
                }
                if (url) {
                    window.location.assign(url);
                }
            });
        } catch (error) {
            console.error("Error creating checkout session:", error);
            alert("An error occurred while creating the checkout session. Please try again.");
            setLoading(false);
        }
    };

    const handleManageSubscription = () => {
        window.location.href = '/my-profile';
    };

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />
            <div className="w-full py-12 md:py-20 px-4 md:px-8 bg-mainBackground">
                <div className="max-w-6xl mx-auto">
                    <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-logoColor mb-8 md:mb-12 text-center">Choose Your Learning Path</h2>

                    <h4 className="text-sm md:text-lg lg:text-xl font-bold text-logoColor mb-4 md:mb-8 text-center">OniKanji is currently in Alpha production. All prices are reduced to reflect this. We are working on rapid development and improvements. Thank you for your support!</h4>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 bg-blueBoxShadow rounded-lg p-4 md:p-8 w-full md:w-auto">
                        {products.map((product, index) => (
                            <div key={product.id} className="bg-white p-8 border-2 border-logoColor rounded-lg shadow-md flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:scale-105">
                                <div className="mb-6 flex justify-center">
                                    <div className="w-52 h-52 relative overflow-hidden" style={{ clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)' }}>
                                        <div className="absolute inset-1 bg-logoColor"></div>
                                        <div className="absolute inset-2" style={{ clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)' }}>
                                            <img 
                                                src={index === 0 ? pricingFishImage : 
                                                     index === 1 ? pricingCameraImage : 
                                                     priciingCicadaImage} 
                                                alt={`${product.name} Plan`} 
                                                className="w-full h-full object-cover" 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-logoColor mb-4">{product.name}</h3>
                                {index === 0 && (
                                    <>
                                        <p className="text-4xl font-bold mb-2">$9.00<span className="text-lg font-normal">/month</span></p>
                                        <p className="text-lg font-normal mb-6">Billed monthly</p>
                                    </>
                                )}
                                {index === 1 && (
                                    <>
                                        <p className="text-4xl font-bold mb-2">$7.00<span className="text-lg font-normal">/month</span></p>
                                        <p className="text-2xl font-bold mb-6">$84.00<span className="text-base font-normal">/year</span></p>
                                    </>
                                )}
                                {index === 2 && (
                                    <>
                                        <p className="text-4xl font-bold mb-2">
                                            <span className="line-through">$290.00</span>
                                            <span className="ml-2">$190.00</span>
                                        </p>
                                        <p className="text-lg font-normal mb-6">One Time Charge</p>
                                    </>
                                )}
                                <ul className="mb-8 flex-grow">
                                    <li className="mb-2">{index === 0 ? "Best for those just starting their Kanji journey" :
                                      index === 1 ? "For committed, serious learners" :
                                      "For lifelong learners and partners"}</li>
                                    <li className="mb-2">✓ Access to core curriculum</li>
                                    <li className="mb-2">✓ Access to all levels</li>
                                    <li className="mb-2">✓ Custom trained learning algorithm</li>
                                    <li className="mb-2">✓ All progress tracking and metrics</li>
                                </ul>
                                {subscriptionStatus === 'active' && subscriptionName === product.name ? (
                                    <button 
                                        onClick={handleManageSubscription}
                                        className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center"
                                    >
                                        Manage Subscription
                                    </button>
                                ) : (
                                    <button 
                                        onClick={() => handleSubscribe(product)}
                                        className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center flex items-center justify-center"
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <ClipLoader color="#014156" size={24} />
                                        ) : (
                                            'Subscribe Now'
                                        )}
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default OnikanjiPricing;
