import React, { useState, useRef, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup } from 'firebase/auth';
import { googleProvider } from "../config/firebase";
import { createCallableFunction } from '../config/firebase';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { useUser } from '../components/utils/UserContext';
import oniKanjiLoginImage from '../images/onikanji-login-image 1.png';
import OniKanjiLogo from '../images/landingPageAssets/onikanji-logo-landing-page.png';

const updateLastLoginDate = createCallableFunction('updateLastLoginDate');

const OniKanjiSignUp = () => {
  const { user, loading: userLoading } = useUser();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const lastSignUpAttempt = useRef(0);

  useEffect(() => {
    if (!userLoading && user) {
      navigate('/home');
    }
  }, [user, userLoading, navigate]);

  if (userLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-mainBackgroundColor">
        <ClipLoader size={50} color="#FF6600" />
      </div>
    );
  }

  if (user) {
    return null; // This will prevent any flash of content before redirecting
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const re = /^[a-zA-Z0-9!@#$%^&*()_+\-={};':"\\|,.<>/?]{6,}$/;
    return re.test(password);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    
    const now = Date.now();
    if (now - lastSignUpAttempt.current < 10000) {
      toast.error("Please wait 10 seconds before trying again.");
      return;
    }
    lastSignUpAttempt.current = now;

    setSignupError("");
    setMessage("");
    setIsLoading(true);

    if (!validateEmail(email)) {
      setSignupError("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setSignupError("Password must be at least 6 characters long and contain only alphanumeric characters and symbols.");
      setIsLoading(false);
      return;
    }

    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("User signed up successfully:", user.uid);
      await sendEmailVerification(user);
      await updateLastLoginDate(user.uid);
      setMessage("Account created! Please check your email for verification.");
      setEmail("");
      setPassword("");
    } catch (error) {
      console.error("Error signing up:", error.code, error.message);
      if (error.code === 'auth/email-already-in-use') {
        setSignupError("Email is already in use.");
      } else {
        setSignupError(`Error: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const signUpWithGoogle = async () => {
    const now = Date.now();
    if (now - lastSignUpAttempt.current < 10000) {
      toast.error("Please wait 10 seconds before trying again.");
      return;
    }
    lastSignUpAttempt.current = now;

    setIsLoading(true);
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User signed up with Google successfully");
      await updateLastLoginDate(result.user.uid);
      
      // Add a 2-second delay before navigating
      setTimeout(() => {
        navigate('/home');
      }, 4000);
      
    } catch (error) {
      console.error("Error signing up with Google:", error);
      if (error.code === 'auth/popup-closed-by-user') {
        // Force a page reset
        window.location.reload();
      } else {
        setSignupError("Failed to sign up with Google. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='min-h-screen flex justify-center items-center bg-mainBackgroundColor p-4'>
      <div className='bg-white rounded-lg shadow-blueBoxShadow flex flex-col lg:flex-row w-full max-w-6xl'>
        <div className='w-full lg:w-1/2 h-64 lg:h-auto flex-shrink-0 bg-gray-200'>
          <img 
            src={oniKanjiLoginImage} 
            alt="Onikanji Login" 
            className='w-full h-full object-cover lg:object-cover rounded-t-lg lg:rounded-l-lg lg:rounded-tr-none' 
          />
        </div>
        <div className='w-full lg:w-1/2 p-8 flex flex-col justify-center'>
          <img 
            src={OniKanjiLogo} 
            alt="Onikanji Logo" 
            className='mb-6 mx-auto h-12 cursor-pointer' 
            onClick={() => window.location.href = '/'} 
          />
          <h2 className='text-2xl font-bold mb-6 text-center text-logoColor'>Sign Up for Onikanji</h2>
          <form className='space-y-4' onSubmit={handleSignUp}>
            <div>
              <label htmlFor="email" className='block text-sm font-medium text-gray-700'>Email address</label>
              <input 
                type="email" 
                id="email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required 
                className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-logoColor focus:border-logoColor' 
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className='block text-sm font-medium text-gray-700'>Password</label>
              <input 
                type={showPassword ? "text" : "password"} 
                id="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required 
                className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-logoColor focus:border-logoColor pr-10'
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 mt-6"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FaEyeSlash className="h-5 w-5 text-gray-500" />
                ) : (
                  <FaEye className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>
            {signupError && (
              <div className="mt-1 text-sm text-red-600">
                {signupError}
              </div>
            )}
            {message && (
              <div className="mt-4 text-lg font-semibold text-green-600 text-center p-4 bg-green-100 rounded-md">
                {message}
              </div>
            )}
            <button 
              type="submit" 
              className='w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-darkOrangeColor hover:bg-darkOrangeColorButtonHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logoColor'
              disabled={isLoading} 
            >
              {isLoading ? (
                <>
                  <ClipLoader size={16} color="#ffffff" className="mr-2" />
                  Signing up...
                </>
              ) : (
                'Sign up'
              )}
            </button>
          </form>
          <div className='mt-6'>
            <div className='relative'>
              <div className='absolute inset-0 flex items-center'>
                <div className='w-full border-t border-gray-300'></div>
              </div>
              <div className='relative flex justify-center text-sm'>
                <span className='px-2 bg-white text-gray-500'>Or continue with</span>
              </div>
            </div>
            <div className='mt-6'>
              <button 
                onClick={signUpWithGoogle}
                disabled={isLoading}
                className='w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logoColor'
              >
                {isLoading ? 'Signing up...' : 'Sign up with Google'}
              </button>
            </div>
          </div>
          <div className='mt-6 text-center'>
            <div className='text-sm'>
              <a href="/login" className='font-medium text-logoColor hover:text-darkOrangeColor'>
                Already have an account? Log in
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OniKanjiSignUp;
