import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import Navbar from '../components/navBar';

const OnikanjiError = ({ user, subscriptionStatus }) => {
    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />
            <div className="w-full py-12 md:py-20 px-4 md:px-8 bg-mainBackground">
                <div className="max-w-6xl mx-auto text-center">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-logoColor mb-4">404</h1>
                    <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-logoColor mb-8">Page Not Found</h2>
                    <p className="text-lg mb-8">Oops! The page you're looking for doesn't exist.</p>
                    <Link to="/" className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300">
                        Go Back to Home
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default OnikanjiError;
