import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase';
import { getFirestore, collection, getDoc, doc } from 'firebase/firestore';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createCallableFunction } from '../config/firebase';

function KanjiAdminPanel() {
    const navigate = useNavigate();
    const { user, loading: userLoading } = useUser();

    useEffect(() => {
        if (!userLoading && !user) {
            navigate('/login');
        }
    }, [userLoading, user, navigate]);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const resetAccount = createCallableFunction('resetAccount');

    const handleResetAccount = async () => {
        try {
            console.log('Attempting to reset account...');
            const result = await resetAccount();
            console.log('Account reset successfully:', result);
        } catch (error) {
            console.error('Error resetting account:', error);
        }
    };


    const resetUserStats = createCallableFunction('resetUserStats');

    const handleResetUserStats = async () => {
        try {
            console.log('Attempting to reset user stats...');
            const result = await resetUserStats();
            console.log('User stats reset successfully:', result);
        } catch (error) {
            console.error('Error resetting user stats:', error);
        }
    };


    const simulateUserReadyForLevelUp = createCallableFunction('simulateUserReadyForLevelUp');

    const handleSimulateUserReadyForLevelUp = async () => {
    try {
        console.log('Simulating user ready for level up...');
        const result = await simulateUserReadyForLevelUp({ userId: user.uid });
        console.log('User simulated as ready for level up:', result);
    } catch (error) {
        console.error('Error simulating user ready for level up:', error);
    }
    };


    return (
        <div className="min-h-screen bg-gray-100">
            <Navbar user={user} onSignOut={handleSignOut} />
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6">Kanji Admin Panel</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <AdminCard
                        title="Account Management"
                        description="Reset user account data"
                        buttonText="Reset Account"
                        onClick={handleResetAccount}
                        buttonColor="bg-blue-500 hover:bg-blue-600"
                    />
                    <AdminCard
                        title="User Statistics"
                        description="Reset user statistics"
                        buttonText="Reset User Stats"
                        onClick={handleResetUserStats}
                        buttonColor="bg-red-500 hover:bg-red-600"
                    />
                    <AdminCard
                        title="Level Up Simulation"
                        description="Simulate user ready for level up"
                        buttonText="Simulate Level Up"
                        onClick={handleSimulateUserReadyForLevelUp}
                        buttonColor="bg-purple-500 hover:bg-purple-600"
                    />
                </div>
            </div>
        </div>
    );
}

function AdminCard({ title, description, buttonText, onClick, buttonColor }) {
    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-2">{title}</h2>
            <p className="text-gray-600 mb-4">{description}</p>
            <button
                onClick={onClick}
                className={`${buttonColor} text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out`}
            >
                {buttonText}
            </button>
        </div>
    );
}

export default KanjiAdminPanel;