import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import { getFirestore, collection, query, where, getDocs, orderBy, limit, startAfter, endBefore } from 'firebase/firestore';
import { BiVolumeFull } from "react-icons/bi";
import { SlArrowRight } from "react-icons/sl";
import { SlArrowLeft } from "react-icons/sl";

function KanjiDetail() {
    const { kanji } = useParams();
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Examples');
    const [kanjiData, setKanjiData] = useState(null);
    const [kanjiList, setKanjiList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [loadingKanjiList, setLoadingKanjiList] = useState(true);

    useEffect(() => {
        if (!userLoading && !user) {
            navigate('/login');
        }
    }, [user, userLoading, navigate]);

    // Fetch current kanji data
    useEffect(() => {
        const fetchKanjiData = async () => {
            const db = getFirestore();

            const fetchCollectionData = async (collectionName) => {
                const kanjiCollection = collection(db, collectionName);
                const kanjiQuery = query(kanjiCollection, where('kanji', '==', kanji));
                const kanjiSnap = await getDocs(kanjiQuery);
                if (!kanjiSnap.empty) {
                    return kanjiSnap.docs[0].data();
                }
                return null;
            };

            const kanjiInfo = await fetchCollectionData('allKanji');
            const kunyomiInfo = await fetchCollectionData('kunyomiKanji');
            const onyomiInfo = await fetchCollectionData('onyomiKanji');

            setKanjiData({
                ...kanjiInfo,
                kunyomi: kunyomiInfo ? {
                    readings: [
                        kunyomiInfo.kunyomiReadingOne,
                        kunyomiInfo.kunyomiReadingTwo,
                        kunyomiInfo.kunyomiReadingThree
                    ].filter(reading => reading && reading !== "None"),
                    vocabWords: ['One', 'Two', 'Three', 'Four'].map(num => ({
                        word: kunyomiInfo[`kunyomiVocabWord${num}`],
                        reading: kunyomiInfo[`kunyomiVocabWordReading${num}`],
                        meaning: kunyomiInfo[`kunyomiVocabWordMeaning${num}`],
                        sampleSentence: kunyomiInfo[`kunyomiSampleSentence${num}`],
                        sampleSentenceMeaning: kunyomiInfo[`kunyomiSampleSentenceMeaning${num}`]
                    })).filter(item => item.word && item.word !== "None")
                } : null,
                onyomi: onyomiInfo ? {
                    readings: [
                        onyomiInfo.onyomiReadingOne,
                        onyomiInfo.onyomiReadingTwo,
                        onyomiInfo.onyomiReadingThree
                    ].filter(reading => reading && reading !== "None"),
                    vocabWords: ['One', 'Two', 'Three', 'Four'].map(num => ({
                        word: onyomiInfo[`onyomiVocabWord${num}`],
                        reading: onyomiInfo[`onyomiVocabWordReading${num}`],
                        meaning: onyomiInfo[`onyomiVocabWordMeaning${num}`],
                        sampleSentence: onyomiInfo[`onyomiSampleSentence${num}`],
                        sampleSentenceMeaning: onyomiInfo[`onyomiSampleSentenceMeaning${num}`]
                    })).filter(item => item.word && item.word !== "None")
                } : null
            });
        };

        fetchKanjiData();
    }, [kanji]);

    // Fetch neighboring kanji IDs
    useEffect(() => {
        const fetchKanjiList = async () => {
            if (kanjiData && kanjiData.lexicographicKanjiID !== undefined) {
                const db = getFirestore();
                const kanjiCollection = collection(db, 'allKanji');
                const currentKanjiID = kanjiData.lexicographicKanjiID;

                try {
                    // Fetch previous 10 kanjis
                    const prevQuery = query(
                        kanjiCollection,
                        orderBy('lexicographicKanjiID', 'desc'),
                        where('lexicographicKanjiID', '<', currentKanjiID),
                        limit(10)
                    );
                    const prevSnap = await getDocs(prevQuery);
                    const prevKanjis = prevSnap.docs.map(doc => doc.data()).reverse(); // reverse to correct order

                    // Fetch next 10 kanjis
                    const nextQuery = query(
                        kanjiCollection,
                        orderBy('lexicographicKanjiID'),
                        where('lexicographicKanjiID', '>', currentKanjiID),
                        limit(10)
                    );
                    const nextSnap = await getDocs(nextQuery);
                    const nextKanjis = nextSnap.docs.map(doc => doc.data());

                    // Include current kanji
                    const kanjiListCombined = [...prevKanjis, kanjiData, ...nextKanjis];

                    setKanjiList(kanjiListCombined.map(k => k.kanji));

                    // Find index of current kanji in the list
                    const index = kanjiListCombined.findIndex(k => k.kanji === kanjiData.kanji);
                    setCurrentIndex(index);
                } catch (error) {
                    console.error("Error fetching kanji list:", error);
                } finally {
                    setLoadingKanjiList(false);
                }
            }
        };

        fetchKanjiList();
    }, [kanjiData]);

    // Navigation functions
    const handlePreviousKanji = useCallback(async () => {
        if (currentIndex > 0) {
            navigate(`/kanji/${kanjiList[currentIndex - 1]}`);
        } else {
            // Fetch more previous kanjis
            await fetchMorePreviousKanjis();
        }
    }, [currentIndex, kanjiList, navigate]);

    const handleNextKanji = useCallback(async () => {
        if (currentIndex < kanjiList.length - 1) {
            navigate(`/kanji/${kanjiList[currentIndex + 1]}`);
        } else {
            // Fetch more next kanjis
            await fetchMoreNextKanjis();
        }
    }, [currentIndex, kanjiList, navigate]);

    // Functions to fetch more kanjis when reaching the end of the current list
    const fetchMorePreviousKanjis = async () => {
        if (kanjiList.length > 0 && kanjiData) {
            const db = getFirestore();
            const kanjiCollection = collection(db, 'allKanji');
            const firstKanjiID = kanjiData.lexicographicKanjiID;

            const prevQuery = query(
                kanjiCollection,
                orderBy('lexicographicKanjiID', 'desc'),
                where('lexicographicKanjiID', '<', firstKanjiID),
                limit(10)
            );
            const prevSnap = await getDocs(prevQuery);
            const prevKanjis = prevSnap.docs.map(doc => doc.data()).reverse();

            if (prevKanjis.length > 0) {
                setKanjiList(prevKanjis.map(k => k.kanji).concat(kanjiList));
                setCurrentIndex(prevKanjis.length + currentIndex);
            }
        }
    };

    const fetchMoreNextKanjis = async () => {
        if (kanjiList.length > 0 && kanjiData) {
            const db = getFirestore();
            const kanjiCollection = collection(db, 'allKanji');
            const lastKanjiID = kanjiData.lexicographicKanjiID;

            const nextQuery = query(
                kanjiCollection,
                orderBy('lexicographicKanjiID'),
                where('lexicographicKanjiID', '>', lastKanjiID),
                limit(10)
            );
            const nextSnap = await getDocs(nextQuery);
            const nextKanjis = nextSnap.docs.map(doc => doc.data());

            if (nextKanjis.length > 0) {
                setKanjiList(kanjiList.concat(nextKanjis.map(k => k.kanji)));
            }
        }
    };

    // Keyboard event listeners for left and right arrow keys
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                handlePreviousKanji();
            } else if (event.key === 'ArrowRight') {
                handleNextKanji();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handlePreviousKanji, handleNextKanji]);

    const handleTextToSpeech = (text, language) => {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = language === 'japanese' ? 'ja-JP' : 'en-US';
        window.speechSynthesis.speak(utterance);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const InfoBox = ({ label, value }) => (
        <div className="p-4 rounded border border-darkBlueColor">
            <p className="text-black text-sm">{label}</p>
            <h3 className="text-lg font-bold text-black">{value}</h3>
        </div>
    );

    // Wait for data to load
    if (userLoading || !kanjiData || loadingKanjiList) {
        return <div>Loading...</div>;
    }

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />

            <div className="bg-mainBackgroundColor min-h-screen flex flex-col items-center p-4">
                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                    <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                        <div className="w-full grid grid-cols-1 sm:grid-cols-4 gap-4 text-center">
                            <InfoBox label="Level" value={kanjiData.level} />
                            <InfoBox label="Joyo Kanji Rank" value={kanjiData.joyoKanjiRank} />
                            <InfoBox label="JLPT" value={kanjiData.JLPT} />
                            <InfoBox label="Frequency Rank" value={kanjiData.top2500MostUsed} />
                        </div>
                        {/* Kanji Character with Navigation Arrows */}
                        <div className="flex items-center justify-center m-4">
                            <button
                                onClick={handlePreviousKanji}
                                disabled={currentIndex === 0 && !kanjiList[currentIndex - 1]}
                                className={`mx-2 ${currentIndex === 0 && !kanjiList[currentIndex - 1] ? 'text-gray-400 cursor-not-allowed' : 'text-black'}`}
                            >
                                <SlArrowLeft size={24} />
                            </button>
                            <div className="text-6xl sm:text-6xl font-bold text-black text-center">{kanji}</div>
                            <button
                                onClick={handleNextKanji}
                                disabled={currentIndex === kanjiList.length - 1 && !kanjiList[currentIndex + 1]}
                                className={`mx-2 ${currentIndex === kanjiList.length - 1 && !kanjiList[currentIndex + 1] ? 'text-gray-400 cursor-not-allowed' : 'text-black'}`}
                            >
                                <SlArrowRight size={24} />
                            </button>
                        </div>
                        <div className="text-center px-2 sm:px-4 py-2 sm:py-3 text-xl sm:text-3xl font-semibold">
                            {kanjiData.primaryMeaning || 'No meaning available'}
                        </div>

                        {kanjiData.secondaryMeaning && (
                            <div className="text-center px-2 sm:px-4 py-2 sm:py-3 text-xl sm:text-3xl font-semibold">
                                {kanjiData.secondaryMeaning.split(',').map(meaning => meaning.trim().charAt(0).toUpperCase() + meaning.trim().slice(1)).join(', ')}
                            </div>
                        )}

                        <div className="flex w-full justify-center mt-4 space-x-4">
                            <div className={`text-center p-2 sm:p-3 text-sm sm:text-base rounded flex-1 ${kanjiData.onyomi?.readings?.length ? 'bg-lightBlueBackground text-lightBlueText' : 'bg-gray-200'}`}>
                                <div className="font-semibold mb-2">On'yomi Readings</div>
                                {kanjiData.onyomi?.readings?.map((reading, index) => (
                                    <div key={index} className="text-3xl sm:text-3xl mt-1 font-medium">{reading}</div>
                                ))}
                            </div>
                            <div className={`text-center p-2 sm:p-3 text-sm sm:text-base rounded flex-1 ${kanjiData.kunyomi?.readings?.length ? 'bg-orange-100 text-orange-800' : 'bg-gray-200'}`}>
                                <div className="font-semibold mb-2">Kun'yomi Readings</div>
                                {kanjiData.kunyomi?.readings?.map((reading, index) => (
                                    <div key={index} className="text-3xl sm:text-3xl mt-1 font-medium">{reading}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                    <div className="grid grid-cols-2 gap-3 sm:gap-4 mb-6">
                        <button
                            className={`text-sm sm:text-base font-semibold p-2 sm:p-3 rounded ${activeTab === 'Examples' ? 'bg-darkOrangeColor text-white' : 'bg-gray-300 text-black'}`}
                            onClick={() => handleTabClick('Examples')}
                        >
                            Examples
                        </button>
                        <button
                            className={`text-sm sm:text-base font-semibold p-2 sm:p-3 rounded ${activeTab === 'Common Expressions' ? 'bg-darkOrangeColor text-white' : 'bg-gray-300 text-black'}`}
                            onClick={() => handleTabClick('Common Expressions')}
                        >
                            Common Expressions
                        </button>
                    </div>
                    {activeTab === 'Examples' && (
                        <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                            <div className="w-full p-4 rounded-lg">
                                <div className="text-xl sm:text-2xl font-semibold text-black">Vocabulary & Examples</div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
                                {/* On'yomi Cards Column */}
                                <div className="space-y-6">
                                    {kanjiData.onyomi?.vocabWords?.map((vocabWord, index) => (
                                        <div key={`onyomi-${index}`} className="bg-lightBlueBackground p-6 rounded-lg shadow-md space-y-4 w-full">
                                            <div className="space-y-2">
                                                <p className="text-darkBlueColor text-sm">Vocabulary Word</p>
                                                <div className="flex flex-col md:flex-row items-start justify-between">
                                                    <div className="text-3xl font-medium text-lightBlueText">{vocabWord.word}</div>
                                                    <div className="text-xl text-darkBlueColor ml-0 md:ml-4">{vocabWord.meaning}</div>
                                                </div>
                                                <p className="text-darkBlueColor text-xl">{vocabWord.reading}</p>
                                            </div>
                                            {vocabWord.sampleSentence && (
                                                <div className="space-y-2 mt-4">
                                                    <p className="text-darkBlueColor text-sm">Sample Sentence</p>
                                                    <div className="flex items-center">
                                                        <div className="text-2xl font-medium text-lightBlueText">
                                                            {vocabWord.sampleSentence.replace(/\(.*?\)/, '')}
                                                        </div>
                                                        <span 
                                                            className="text-darkBlueColor text-2xl ml-2 cursor-pointer"
                                                            onClick={() => handleTextToSpeech(vocabWord.sampleSentence.replace(/\(.*?\)/, ''), 'japanese')}
                                                        >
                                                            <BiVolumeFull />
                                                        </span>
                                                    </div>
                                                    <div className="text-darkBlueColor text-xl">{vocabWord.sampleSentenceMeaning}</div>
                                                    {vocabWord.sampleSentence.match(/\(.*?\)/) && (
                                                        <div className="text-darkBlueColor text-sm italic mt-2">
                                                            {vocabWord.sampleSentence.match(/\(.*?\)/)[0]}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                                {/* Kun'yomi Cards Column */}
                                <div className="space-y-6">
                                    {kanjiData.kunyomi?.vocabWords?.map((vocabWord, index) => (
                                        <div key={`kunyomi-${index}`} className="bg-orange-100 p-6 rounded-lg shadow-md space-y-4 w-full">
                                            <div className="space-y-2">
                                                <p className="text-orange-600 text-sm">Vocabulary Word</p>
                                                <div className="flex flex-col md:flex-row items-start justify-between">
                                                    <div className="text-3xl font-medium text-orange-900">{vocabWord.word}</div>
                                                    <p className="text-xl text-orange-700 ml-0 md:ml-4">{vocabWord.meaning}</p>
                                                </div>
                                                <p className="text-orange-700 text-xl">{vocabWord.reading}</p>
                                            </div>
                                            {vocabWord.sampleSentence && (
                                                <div className="space-y-2 mt-4">
                                                    <p className="text-orange-600 text-sm">Sample Sentence</p>
                                                    <div className="flex items-center">
                                                        <div className="text-2xl font-medium text-orange-900">
                                                            {vocabWord.sampleSentence.replace(/\(.*?\)/, '')}
                                                        </div>
                                                        <span 
                                                            className="text-orange-700 text-2xl ml-2 cursor-pointer"
                                                            onClick={() => handleTextToSpeech(vocabWord.sampleSentence.replace(/\(.*?\)/, ''), 'japanese')}
                                                        >
                                                            <BiVolumeFull />
                                                        </span>
                                                    </div>
                                                    <div className="text-orange-700 text-xl">{vocabWord.sampleSentenceMeaning}</div>
                                                    {vocabWord.sampleSentence.match(/\(.*?\)/) && (
                                                        <div className="text-orange-600 text-sm italic mt-2">
                                                            {vocabWord.sampleSentence.match(/\(.*?\)/)[0]}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 'Common Expressions' && (
                        <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                            <div className="w-full p-4 rounded-lg">
                                <div className="text-xl sm:text-2xl font-semibold text-black">Common Expressions</div>
                            </div>
                            <div className="text-center text-lg">Placeholder for common expressions related to {kanji}</div>
                        </div>
                    )}
                </div>



            </div>
        </div>
    );
}




export default KanjiDetail;