import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import Footer from '../components/footer';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import { ClipLoader } from 'react-spinners';

import { createCallableFunction } from '../config/firebase';


function MyProfilePage() {

    const navigate = useNavigate();
    const { user, username, loading: userLoading, subscriptionStatus, subscriptionName, subscriptionEndDate, subscriptionCancelAtPeriodEnd, error } = useUser();

    const [algoSettings, setAlgoSettings] = useState({
        preset: 'Samurai',
        dailyMaxReviewLimit: 100,
        reviewFrequency: 'Medium',
        questionDifficulty: 'Normal'
    });

    const [newUsername, setNewUsername] = useState('');

    const algoPresets = {
        Samurai: { dailyMaxReviewLimit: 100, reviewFrequency: 'Medium', questionDifficulty: 'Normal' },
        Godlike: { dailyMaxReviewLimit: 200, reviewFrequency: 'High', questionDifficulty: 'Hard' },
        Adventurer: { dailyMaxReviewLimit: 50, reviewFrequency: 'Low', questionDifficulty: 'Easy' },
        Warrior: { dailyMaxReviewLimit: 150, reviewFrequency: 'Normal', questionDifficulty: 'Hard' }
    };


    useEffect(() => {
        if (!userLoading && !user) {
            navigate('/login');
        }
    }, [user, userLoading, navigate]);

    const handleAlgoPresetChange = (e) => {
        const selectedPreset = e.target.value;
        setAlgoSettings({ ...algoPresets[selectedPreset], preset: selectedPreset });
    };

    const handleAlgoSettingChange = (setting, value) => {
        setAlgoSettings(prev => ({ ...prev, [setting]: value }));
    };

    const handleSaveAlgoSettings = async () => {
        try {
            const saveAlgoSettings = createCallableFunction('saveUserAlgoSettings');
            const result = await saveAlgoSettings({ preset: algoSettings.preset });
            if (result.data.success) {
                alert('Algorithm settings saved successfully!');
            } else {
                throw new Error('Failed to save settings');
            }
        } catch (error) {
            console.error('Error saving algorithm settings:', error);
            alert('Failed to save algorithm settings. Please try again.');
        }
    };

    const handleUsernameChange = async () => {
        if (newUsername.length < 3 || !/^[a-zA-Z0-9]+$/.test(newUsername)) {
            alert('Username must be at least 3 characters long and contain only alphanumeric characters.');
            return;
        }

        try {
            const changeUsername = createCallableFunction('changeUsername');
            const result = await changeUsername({ newUsername });
            if (result.data.success) {
                alert('Username changed successfully!');
                setNewUsername('');
            }
        } catch (error) {
            console.error('Error changing username:', error);
            if (error.code === 'already-exists') {
                alert('This username is already taken. Please choose a different one.');
            } else {
                alert('Failed to change username. Please try again.');
            }
        }
    };

    const handlePasswordReset = async () => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            alert('You must be logged in to reset your password.');
            return;
        }

        try {
            await sendPasswordResetEmail(auth, user.email);
            alert('Password reset email sent. Please check your inbox.');
        } catch (error) {
            console.error('Error sending password reset email:', error);
            alert('Failed to send password reset email. Please try again.');
        }
    };

    const [emailSettings, setEmailSettings] = useState({
        reviewReminderEmail: true,
        levelUpEmail: true,
        salesPromoEmail: true,
        accountManagementEmail: true
    });

    const handleEmailSettingChange = (setting) => {
        setEmailSettings(prev => ({ ...prev, [setting]: !prev[setting] }));
    };

    const handleSaveEmailSettings = async () => {
        try {
            const saveEmailSettings = createCallableFunction('saveUserEmailSettings');
            const result = await saveEmailSettings(emailSettings);
            if (result.data.success) {
                alert('Email settings saved successfully!');
            } else {
                throw new Error('Failed to save settings');
            }
        } catch (error) {
            console.error('Error saving email settings:', error);
            alert(error.message || 'Failed to save email settings. Please try again.');
        }
    };

    const [resetLevel, setResetLevel] = useState('');

    const handleResetToLevel = async () => {
        if (!resetLevel || isNaN(resetLevel) || resetLevel < 1) {
            alert('Please enter a valid level number.');
            return;
        }

        try {
            const resetAccountToLevel = createCallableFunction('resetAccountToLevel');
            await resetAccountToLevel({ level: parseInt(resetLevel) });
            alert(`Account reset to level ${resetLevel} successfully!`);
            setResetLevel('');
        } catch (error) {
            console.error('Error resetting account level:', error);
            alert('Failed to reset account level. Please try again.');
        }
    };

    const [isManageSubscriptionLoading, setIsManageSubscriptionLoading] = useState(false);

    const handleManageSubscription = async () => {
        setIsManageSubscriptionLoading(true);
        try {
            const functions = getFunctions(getApp(), 'us-east1');
            const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

            const { data } = await createPortalLink({
                returnUrl: window.location.origin,
                locale: "auto"
            });

            window.location.assign(data.url);
        } catch (error) {
            console.error("Error creating portal link:", error);
            alert("An error occurred while trying to manage your subscription. Please try again.");
        } finally {
            setIsManageSubscriptionLoading(false);
        }
    };

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />

            <div className="bg-mainBackgroundColor min-h-screen flex flex-col items-center p-4">
                <h1 className="text-2xl font-bold text-darkBlueColor mt-10">Welcome, {username}</h1>

                {/* Learning Algorithm Settings */}
                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10 relative">
                    <h2 className="text-2xl font-bold text-darkBlueColor mb-6">Learning Algorithm Settings</h2>
                    <div className="space-y-6 opacity-50">
                        <div>
                            <label className="block text-sm font-semibold text-darkBlueColor mb-2">Select Preset:</label>
                            <select
                                className="w-full bg-gray-50 border-pink-300 rounded-md shadow-sm focus:border-pink-500 focus:ring focus:ring-pink-200 focus:ring-opacity-50 py-2 px-3"
                                value={algoSettings.preset}
                                onChange={handleAlgoPresetChange}
                                disabled
                            >
                                {Object.keys(algoPresets).map(preset => (
                                    <option key={preset} value={preset}>{preset}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-semibold text-darkBlueColor mb-2">Daily Maximum Review Limit:</label>
                            <input
                                type="number"
                                min="1"
                                max="500"
                                value={algoSettings.dailyMaxReviewLimit}
                                onChange={(e) => handleAlgoSettingChange('dailyMaxReviewLimit', parseInt(e.target.value))}
                                className="w-full bg-gray-50 border-pink-300 rounded-md shadow-sm focus:border-pink-500 focus:ring focus:ring-pink-200 focus:ring-opacity-50 py-2 px-3"
                                disabled
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-semibold text-darkBlueColor mb-2">Review Frequency:</label>
                            <input
                                type="text"
                                value={algoSettings.reviewFrequency}
                                readOnly
                                className="w-full bg-gray-50 border-pink-300 rounded-md shadow-sm focus:border-pink-500 focus:ring focus:ring-pink-200 focus:ring-opacity-50 py-2 px-3"
                                disabled
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-semibold text-darkBlueColor mb-2">Question Difficulty:</label>
                            <input
                                type="text"
                                value={algoSettings.questionDifficulty}
                                readOnly
                                className="w-full bg-gray-50 border-pink-300 rounded-md shadow-sm focus:border-pink-500 focus:ring focus:ring-pink-200 focus:ring-opacity-50 py-2 px-3"
                                disabled
                            />
                        </div>
                        <div className="mt-6">
                            <button
                                className="w-full bg-logoColor hover:bg-logoColorHover text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                                onClick={handleSaveAlgoSettings}
                                disabled
                            >
                                Save Algorithm Settings
                            </button>
                        </div>
                    </div>
                    <div className="absolute inset-0 bg-gray-200 bg-opacity-75 flex items-center justify-center rounded-lg">
                        <span className="text-2xl font-bold text-darkBlueColor">Coming Soon</span>
                    </div>
                </div>

                {/* Account Management */}
                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                    <h2 className="text-2xl font-bold text-darkBlueColor mb-6">Account Management</h2>
                    <div className="space-y-6">
                        <div>
                            <label className="block text-sm font-semibold text-darkBlueColor mb-2">Change Username:</label>
                            <input
                                type="text"
                                className="w-full bg-gray-50 border-pink-300 rounded-md shadow-sm focus:border-pink-500 focus:ring focus:ring-pink-200 focus:ring-opacity-50 py-2 px-3"
                                placeholder="New Username"
                                value={newUsername}
                                onChange={(e) => setNewUsername(e.target.value)}
                            />
                            <button
                                className="mt-3 bg-logoColor hover:bg-logoColorHover text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                                onClick={handleUsernameChange}
                            >
                                Update Username
                            </button>
                        </div>
                        <div>
                            <label className="block text-sm font-semibold text-darkBlueColor mb-2">Reset Password:</label>
                            <button
                                className="bg-logoColor hover:bg-logoColorHover text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                                onClick={handlePasswordReset}
                            >
                                Send Password Reset Email
                            </button>
                        </div>
                    </div>
                </div>
                
                {/* Manage Subscription */}
                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                    <h2 className="text-xl font-bold text-darkBlueColor mb-4">Manage Subscription</h2>
                    {userLoading ? (
                        <p>Loading subscription information...</p>
                    ) : error ? (
                        <p className="text-red-600">Error: {error}</p>
                    ) : subscriptionStatus ? (
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-semibold text-darkBlueColor mb-2">Your Current Subscription:</label>
                                <p className="text-lg font-bold text-pink-600">{subscriptionName || 'Unknown Subscription'}</p>
                                <p className="text-sm text-gray-600 mt-1">Status: {subscriptionStatus}</p>
                                {subscriptionEndDate && (
                                    <p className="text-sm text-gray-600 mt-1">
                                        {subscriptionCancelAtPeriodEnd ? 'Ends on:' : 'Renews on:'} {subscriptionEndDate}
                                    </p>
                                )}
                            </div>
                            <div>
                                <button
                                    className="bg-logoColor hover:bg-logoColorHover text-white font-bold py-3 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 text-sm sm:text-base flex items-center"
                                    onClick={handleManageSubscription}
                                    disabled={isManageSubscriptionLoading}
                                >
                                    {isManageSubscriptionLoading ? (
                                        <>
                                            <ClipLoader size={16} color="#014156" className="mr-2" />
                                            Processing...
                                        </>
                                    ) : (
                                        'Manage Subscription'
                                    )}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <p className="text-lg">You don't have an active subscription.</p>
                            <button
                                className="bg-logoColor hover:bg-logoColorHover text-white font-bold py-3 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 text-sm sm:text-base"
                                onClick={() => navigate('/pricing')}
                            >
                                Subscribe Now
                            </button>
                        </div>
                    )}
                </div>
                
                {/* Email Settings */}
                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                    <h2 className="text-xl font-bold text-darkBlueColor mb-4">Email Settings</h2>
                    <div className="space-y-4">
                        {Object.entries(emailSettings).map(([key, value]) => (
                            <div key={key} className="flex items-center justify-between">
                                <span className="text-lg font-semibold text-darkBlueColor">
                                    {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')} Emails
                                </span>
                                <div className="flex space-x-2">
                                    <button
                                        className={`${value ? 'bg-logoColor hover:bg-logoColorHover text-white' : 'bg-gray-300'} text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                                        onClick={() => handleEmailSettingChange(key)}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className={`${!value ? 'bg-logoColor hover:bg-logoColorHover text-white' : 'bg-gray-300'} text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                                        onClick={() => handleEmailSettingChange(key)}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button
                        className="mt-6 bg-logoColor hover:bg-logoColorHover text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                        onClick={handleSaveEmailSettings}
                    >
                        Save Changes
                    </button>
                </div>

                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                    <h2 className="text-2xl font-bold text-red-600 mb-6">Danger Zone</h2>
                    <div className="space-y-6">
                        <div>
                            <label className="block text-sm font-semibold text-red-600 mb-2">Reset Account to Previous Level:</label>
                            <div className="flex space-x-2">
                                <input
                                    type="number"
                                    placeholder="Enter level"
                                    className="w-full border-red-300 bg-gray-50 rounded-md shadow-sm focus:border-red-500 focus:ring focus:ring-red-200 focus:ring-opacity-50 py-2 px-3"
                                    value={resetLevel}
                                    onChange={(e) => setResetLevel(e.target.value)}
                                />
                                <button
                                    className="bg-red-600 hover:bg-red-800 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                                    onClick={handleResetToLevel}
                                >
                                    Reset to Level
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default MyProfilePage;